
import http from '../../axios'
import { setStorage } from '@/shared/userStorage'

export const loginJWT = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('/clienteUsuario/login', payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

export const setUser = (response) => {
    const { accessToken, user, roles, menus, resource  } = response.data

    setStorage('accessToken', accessToken)
    setStorage('timeLogin', new Date())
    setStorage('idUser', user.id)
    setStorage('roles', roles)
    setStorage('menus', JSON.stringify(menus))
    setStorage('userInfo', JSON.stringify(user))
    setStorage('aplicacao', resource.sistema || resource.sistemas[0])

}

export const forgotPassword = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('/clienteUsuario/esqueceusenha', payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

export const resetPassword = (payload) =>{
    return new Promise((resolve, reject) => {
        http.post('/clienteUsuario/redefinirsenha', payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}

export const loginProduto = (payload) => {
    return new Promise((resolve, reject) => {
        http.post('/clienteUsuario/loginProduto', payload)
        .then((response) => resolve(response))
        .catch((error) => reject(error))
    })
}