<template>
  <v-card-login :caminho="logo">
    <v-card-content>
      <validation-observer ref="loginForm" #default="{ invalid }">
        <b-form
          class="auth-login-form mt-2"
          style="padding: 1rem !important"
          @submit.prevent="validate"
        >
          <p style="font-size: 12px">
            Informe seu login para definir uma nova senha:
          </p>
          <!-- email -->
          <b-form-group label-for="email" label="E-mail">
            <validation-provider
              #default="{ errors }"
              name="E-mail"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="userEmail"
                name="login-email"
                autofocus
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!--  button -->
          <div class="d-flex justify-content-between">
            <v-button
              :style="lengthScreen.width < breakPoint ? 'flex-basis: 44% !important' : ''"
              label="Voltar"
              icone="ArrowLeft"
              @onSubmit="back"
            />
            <v-button
            :style="lengthScreen.width < breakPoint ? 'flex-basis: 44% !important' : ''"
              label="Enviar"
              icone="Send"
              :disabled="invalid"
              @onSubmit="validate"
            />
          </div>
        </b-form>
      </validation-observer>
    </v-card-content>
  </v-card-login>
</template>

<script>
// Libs de Validação
import { ValidationProvider, ValidationObserver } from "vee-validate";

// Libs do Bootstrap
import {
  BAlert,
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BCard,
  BLink,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BRow,
  BCol,
} from "bootstrap-vue";

// Cahamda de component do projeto
import { required, email } from "@validations";
import { heightFade } from "@core/directives/animations";
import Ripple from "vue-ripple-directive";
// request
import { forgotPassword } from "@/webservice/request/auth";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { caminhoLogo, breakPointScreen } from "@/plugins/contantes";

import { GET_URL } from "@/plugins/contantes";

export default {
  components: {
    // BSV
    BAlert,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BRow,
    ToastificationContent,
    BCol,
  },
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  data() {
    return {
      userEmail: "",
      status: "",
      erro: "",
      required,
      email,
      lengthScreen: {
        width: 0,
        height: 0,
      },
    };
  },
  computed: {
    logo() {
      return caminhoLogo();
    },
    breakPoint () {
      return breakPointScreen('md')
    }
  },
  methods: {
    back() {
      this.$router.replace("/login");
    },
    getLengthScreen() {
      this.lengthScreen.width = window.innerWidth;
      this.lengthScreen.height = window.innerHeight;
    },

    validate() {
      this.$refs.loginForm.validate().then((sucess) => {
        if (sucess) {
          this.onSubmit();
        }
      });
    },

    onSubmit() {
      const payload = {
        email: this.userEmail,
        empresaUrl: GET_URL(),
        tipo: "redefinirSenha"
      };
      this.$loading.start();
      forgotPassword(payload)
        .then((response) => {
          this.$loading.done();
          const { success, message } = response.data
          
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                icon: success ? "CheckIcon" : "AlertCircleIcon",
                text: message[0],
                title: success ? "SUCESSO" : "ATENÇÃO",
                variant: success ? "success" : "warning",
                toaster: "b-toaster-bottom-right",
              },
            },
            {
              position: "bottom-right",
              timeout: 8000,
            }
          );
          if (success)  this.$router.push("/login").catch(() => {});
        })
        .catch((error) => {
          this.$loading.done();
          this.$notification(error, this);
        });
    },
  },

  created() {
    window.addEventListener("resize", this.getLengthScreen);
    this.getLengthScreen();
  },
  destroyed() {
    window.addEventListener("resize", this.getLengthScreen);
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

</style>